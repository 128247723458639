<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container class="px-6 mt-n16">
      <v-row>
        <v-col class="mx-auto pt-0" lg="8">
          <v-card class="card-shadow mb-30">
            <div class="card-header-padding card-border-bottom">
              <p class="font-weight-600 text-h3 text-typo mb-0">Headings</p>
            </div>
            <v-card-text class="card-padding">
              <v-row v-for="item in headings" :key="item.type">
                <v-col cols="12" sm="3" class="d-flex align-end py-4">
                  <span
                    class="text-h5 text-uppercase text-muted font-weight-600"
                    >{{ item.type }}</span
                  >
                </v-col>

                <v-col cols="12" sm="9" class="py-4">
                  <div class="text-typo font-weight-600" :class="item.heading">
                    Argon Dashboard PRO
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow mb-30">
            <div class="card-header-padding card-border-bottom">
              <p class="font-weight-600 text-h3 text-typo mb-0">
                Display Titles
              </p>
            </div>
            <v-card-text class="card-padding">
              <v-row v-for="item in display" :key="item.type">
                <v-col cols="12" sm="3" class="d-flex align-end py-4">
                  <span
                    class="text-h5 text-uppercase text-muted font-weight-600"
                    >{{ item.type }}</span
                  >
                </v-col>

                <v-col cols="12" sm="9" class="py-4">
                  <div class="text-typo font-weight-600" :class="item.heading">
                    Argon Dashboard PRO
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow mb-30">
            <div class="card-header-padding card-border-bottom">
              <p class="font-weight-600 text-h3 text-typo mb-0">
                Specialized titles
              </p>
            </div>
            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="12" sm="3" class="d-flex align-end py-4">
                  <span
                    class="text-h5 text-uppercase text-muted font-weight-600"
                    >Heading</span
                  >
                </v-col>

                <v-col cols="12" sm="9" class="py-4">
                  <div class="text-typo font-weight-600 heading text-uppercase">
                    Argon Dashboard PRO
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3" class="d-flex align-end py-4">
                  <span
                    class="text-h5 text-uppercase text-muted font-weight-600"
                    >Heading Title</span
                  >
                </v-col>

                <v-col cols="12" sm="9" class="py-4">
                  <div
                    class="font-weight-600 heading-title text-warning text-uppercase"
                  >
                    Argon Dashboard PRO
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3" class="d-flex align-end py-4">
                  <span
                    class="text-h5 text-uppercase text-muted font-weight-600"
                    >Heading Section</span
                  >
                </v-col>

                <v-col cols="12" sm="9" class="py-4">
                  <h2 class="display-h-3 text-typo font-weight-600">
                    Header with small subtitle
                  </h2>
                  <p class="lead text-muted font-weight-light mt-5 mb-0">
                    According to the National Oceanic and Atmospheric
                    Administration, Ted, Scambos, NSIDClead scentist, puts the
                    potentially record maximum.
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <p class="font-weight-600 text-h3 text-typo mb-0">Paragraphs</p>
            </div>
            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="12" sm="3" class="d-flex align-end py-4">
                  <span
                    class="text-h5 text-uppercase text-muted font-weight-600"
                    >Paragraph</span
                  >
                </v-col>

                <v-col cols="12" sm="9" class="py-4">
                  <p class="text-body font-weight-light font-size-root mb-0">
                    I will be the leader of a company that ends up being worth
                    billions of dollars, because I got the answers. I understand
                    culture. I am the nucleus. I think that’s a responsibility
                    that I have, to push possibilities, to show people, this is
                    the level that things could be at.
                  </p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3" class="d-flex align-end py-4">
                  <span
                    class="text-h5 text-uppercase text-muted font-weight-600"
                    >Lead Text</span
                  >
                </v-col>

                <v-col cols="12" sm="9" class="py-4">
                  <p class="text-body font-weight-light lead mb-0">
                    I will be the leader of a company that ends up being worth
                    billions of dollars, because I got the answers. I understand
                    culture. I am the nucleus. I think that’s a responsibility
                    that I have, to push possibilities, to show people, this is
                    the level that things could be at.
                  </p>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="3" class="d-flex align-end py-4">
                  <span
                    class="text-h5 text-uppercase text-muted font-weight-600"
                    >Quote</span
                  >
                </v-col>

                <v-col cols="12" sm="9" class="py-4">
                  <blockquote class="blockquote font-size-root pb-0 px-0">
                    <p class="mb-0 text-body">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Integer posuere erat a ante.
                    </p>
                    <footer class="blockquote-footer font-weight-regular">
                      Someone famous in
                      <cite title="Source Title">Source Title</cite>
                    </footer>
                  </blockquote>
                </v-col>
              </v-row>

              <v-row v-for="item in colors" :key="item.name">
                <v-col cols="12" sm="3" class="d-flex align-end py-4">
                  <span
                    class="text-h5 text-uppercase text-muted font-weight-600"
                    >{{ item.name }}</span
                  >
                </v-col>

                <v-col cols="12" sm="9" class="py-4">
                  <p
                    class="ma-0 font-weight-light font-size-root"
                    :class="item.color"
                  >
                    I will be the leader of a company that ends up being worth
                    billions of dollars, because I got the answers...
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "typography",
  components: {
    HeaderTopDashboard,
  },
  data() {
    return {
      colors: [
        {
          name: "Muted Text",
          color: "text-muted",
        },
        {
          name: "Primary Text",
          color: "text-primary",
        },
        {
          name: "Info Text",
          color: "text-info",
        },
        {
          name: "Success Text",
          color: "text-succes",
        },
        {
          name: "Warning Text",
          color: "text-warning",
        },
        {
          name: "Danger Text",
          color: "text-danger",
        },
      ],
      headings: [
        {
          type: "heading 1",
          heading: "text-h1",
        },
        {
          type: "heading 2",
          heading: "text-h2",
        },
        {
          type: "heading 3",
          heading: "text-h3",
        },
        {
          type: "heading 4",
          heading: "text-h4",
        },
        {
          type: "heading 5",
          heading: "text-h5",
        },
        {
          type: "heading 6",
          heading: "text-h6",
        },
      ],
      display: [
        {
          type: "display 1",
          heading: "display-h-1",
        },
        {
          type: "display 2",
          heading: "display-h-2",
        },
        {
          type: "display 3",
          heading: "display-h-3",
        },
        {
          type: "display 4",
          heading: "display-h-4",
        },
      ],
    };
  },
};
</script>
